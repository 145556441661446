import {
  FIELDS_ROLES,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  FIELDS,
} from '../constants/roles'
import { initBiLogger } from '../utils/bi'
import { getAppVersion, getSentryDSN, serializeError, fetcher } from '../utils/utils'
import * as _ from 'lodash'
import {
  escapeRegExp,
  innerText,
  isUploadButton,
  isCaptchaField,
  addContactsValidation,
  getCaptchaField,
  onCaptchaVerify,
  onCaptchaTimeout,
  isTemplate,
  isPreviewMode,
  shouldSendData,
  getValidCollectionId,
  findPlugin,
} from './viewer-utils'
import { strategies } from './strategy/strategies'
import {
  DEFAULT_SUBMIT_ERROR,
  ERROR_TYPE_TAG_KEY,
  initSubmitUtils,
  SUBMIT_ERROR_TYPES,
  SubmitUtils,
} from './submit-utils'
import { FormsFieldPreset } from '../constants/field-types'
import translations from './services/translations'
import { FormPlugin } from '../constants/plugins'
import { isPaymentAllowed } from './services/payment-services'
import { FORMS_APP_DEF_ID, FORMS_WIDGET_ID } from '../constants'
import { VIEWER_ORIGIN, EVENTS } from '../constants/bi-viewer'
import Experiments from '@wix/wix-experiments'

const ERROR_COLOR = '#FF4040'

let metaSiteId
let visitorId

let biLogger: any = {}
let linksUtil
let viewerAppUrl
let isFedopsReport = false
let fedopsLogger
let submitUtils: SubmitUtils
let ravenInstance

const fetchTranslations = fetcher()
const loadTranslations = () => fetchTranslations.getData

const experimentsFetcher = fetcher()
export const getExperiments = () => experimentsFetcher.getData

const loadExperiments = ravenInstance => {
  const fallbackExperiments = {
    enabled: (_key: string) => false,
  } as Experiments

  try {
    const experiments = new Experiments({ baseUrl: 'https://www.wix.com' })
    experiments
      .load('wix-form-builder')
      .then(() => {
        experimentsFetcher.resolveData(experiments)
      })
      .catch(() => {
        ravenInstance.captureMessage(`failed to load experiments promise`)
        experimentsFetcher.resolveData(fallbackExperiments)
      })
  } catch {
    ravenInstance.captureMessage(`failed to load experiments`)
    experimentsFetcher.resolveData(fallbackExperiments)
  }
}

export const initAppForPage = (
  initAppParams,
  platformApi,
  scopedGlobalSdkApis,
  platformServicesAPI
) => {
  if (platformServicesAPI.fedOpsLoggerFactory.getLoggerForWidget) {
    isFedopsReport = true
    fedopsLogger = platformServicesAPI.fedOpsLoggerFactory.getLoggerForWidget({
      appId: FORMS_APP_DEF_ID,
      widgetId: FORMS_WIDGET_ID,
    })
  }

  const release = getAppVersion()
  const isQa = scopedGlobalSdkApis.location.query.isqa

  ravenInstance = platformServicesAPI.monitoring.createMonitor(getSentryDSN(true), data => {
    data.logger = 'logger-viewer-app'
    data.release = release

    if (isQa) {
      data.isQa = true
    }

    return data
  })

  submitUtils = initSubmitUtils(ravenInstance)

  loadExperiments(ravenInstance)

  const { url } = initAppParams
  const { links } = platformApi
  viewerAppUrl = url
  linksUtil = links

  metaSiteId = platformServicesAPI.bi.metaSiteId
  visitorId = platformServicesAPI.bi.visitorId

  return Promise.resolve()
}

const appLoadStarted = () => {
  if (isFedopsReport) {
    fedopsLogger.appLoadStarted()
  }
}

const appLoaded = () => {
  if (isFedopsReport) {
    fedopsLogger.appLoaded()
  }
}

const getFormName = $w => {
  const form = $w(`@${ROLE_FORM}`)
  return {
    form_comp_id: form.uniqueId,
    form_name: form.connectionConfig.formName,
  }
}

const paymentStatusIsValid = status => ['Successful', 'Offline', 'Pending'].includes(status)

const getFormParamsForBi = ($w, fields, wixLocation) => ({
  visitor_id: visitorId,
  num_of_attachments: getAttachmentsCount(fields),
  form_url: wixLocation.url || '',
  ...getFormName($w),
})

const logPublishSitePopupOpened = $w =>
  biLogger.log({
    evid: EVENTS.PUBLISH_SITE_PANEL_OPENED,
    form_comp_id: getFormName($w).form_comp_id,
    builderOrigin: VIEWER_ORIGIN,
  })

const getSubmitErrorParamsForBi = ({ $w, fields, wixLocation, reason, reason_body }) => ({
  reason,
  reason_body,
  ...getFormParamsForBi($w, fields, wixLocation),
})

const getAttachmentsCount = fields =>
  _.filter(fields, field => isUploadButton(field) && field.value.length > 0).length

const getFieldValidity = fields => {
  const valueMissing = 'valueMissing'
  const errorOrder = [
    valueMissing,
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  let errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => {
    if (isCaptchaField(field)) {
      const missingToken = _.isEmpty(field.token)

      if (missingToken) {
        errorType = valueMissing
      }

      return missingToken
    }

    return _.get(field, `validity.${errorType}`)
  })

  return `${errorType} : ${_.get(field, 'connectionConfig.fieldType')}`
}

const showFormError = (message, errorMessage) => {
  if (!_.get(message, 'html')) {
    return
  }
  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = errorMessage
  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }
  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)
  message.show()
}

const resetCrucialFields = $w => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    captchaField.reset()
  }
}

const onSubmit = async (
  {
    $w,
    $message = {},
    wixLocation,
    wixWindow,
    wixSite,
    wixPay,
    isPaymentForm,
    getUploadFieldsData = null,
    fillFieldsValues = null,
    isMultiStepForm,
  },
  strategy
) => {
  let fields = []
  let $submitButton

  const postSubmitActions = (shouldShowSubmissionSuccess = true) => {
    if (shouldShowSubmissionSuccess) {
      submitUtils.resetFields(fields)
      strategy.postSubmission()
    }

    if (!isTemplate(wixLocation)) {
      submitUtils.sendWixAnalytics({ wixSite, wixLocation, wixWindow })
    }
  }

  try {
    biLogger.log({
      evid: EVENTS.USER_CLICKS_SUBMIT,
      ...getFormParamsForBi($w, fields, wixLocation),
    })

    const experiments = await getExperiments()

    try {
      await loadTranslations()
    } catch (err) {
      ravenInstance.captureMessage(err)
    }

    $submitButton = submitUtils.getSubmitButton($w)
    $submitButton.disable()

    fields = submitUtils.getFields({ $w, roles: FIELDS_ROLES })
    if (!submitUtils.validateFields({ fields, strategy })) {
      biLogger.log({
        evid: EVENTS.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'field validity',
          reason_body: getFieldValidity(fields),
        }),
      })

      $submitButton.enable()

      return false
    }

    const shouldShowPublishSitePopupWhenInPreviewMode = async () => {
      const instance = wixSite.getAppToken(FORMS_APP_DEF_ID)

      return (
        isPreviewMode(wixWindow) &&
        isPaymentForm &&
        viewerAppUrl &&
        (await isPaymentAllowed(ravenInstance)(instance))
      )
    }

    if (await shouldShowPublishSitePopupWhenInPreviewMode()) {
      logPublishSitePopupOpened($w)
      const publishSitePopupUrl = () =>
        viewerAppUrl
          .split('/')
          .slice(0, -1)
          .concat(['statics', `viewer-publish-site-panel.html`])
          .join('/')
      await wixWindow.openModal(`${publishSitePopupUrl()}?msid=${metaSiteId}`, {
        width: 500,
        height: 247,
        theme: 'BARE',
      })
      $submitButton.enable()
      return false
    }

    let attachments = [],
      serverRequest

    if (shouldSendData(wixLocation, wixWindow, experiments)) {
      let fieldsWithoutAttachments = fields
      if (isMultiStepForm) {
        fillFieldsValues(fields)
        attachments = getUploadFieldsData()
        fieldsWithoutAttachments = fields.filter(
          ({ uniqueId }) => !_.find(attachments, { uniqueId })
        )
      }

      attachments = [
        ...attachments,
        ...(await submitUtils.getAttachments(fieldsWithoutAttachments)),
      ]

      serverRequest = await submitUtils.sendFieldsToServer({
        strategy,
        attachments,
        fields,
        experiments,
        viewMode: wixWindow.viewMode,
      })
    } else {
      postSubmitActions()
      $submitButton.enable()
      return true
    }

    if (serverRequest && serverRequest.ok) {
      let serverResponse

      if (serverRequest.json) {
        serverResponse = await serverRequest.json()
      }

      let shouldShowSuccessMessage = true

      const orderId = _.get(serverResponse, 'orderId')
      if (orderId) {
        const userInfo = getUserInfo(fields)
        const paymentResponse = await wixPay.startPayment(orderId, {
          userInfo,
          allowManualPayment: true,
        })
        if (!paymentStatusIsValid(paymentResponse.status)) {
          shouldShowSuccessMessage = false
        }
      }

      // this event should be after all server requests (wix forms + wix data)
      biLogger.log({
        evid: EVENTS.SUBMISSION_SUCCESS,
        ...getFormParamsForBi($w, fields, wixLocation),
      })

      postSubmitActions(shouldShowSuccessMessage)
    } else {
      resetCrucialFields($w)
      biLogger.log({
        evid: EVENTS.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'server error',
          reason_body: _.get(serverRequest, 'status'),
        }),
      })
    }

    $submitButton.enable()
  } catch (err) {
    if ($submitButton) {
      $submitButton.enable()
    }

    resetCrucialFields($w)

    const context = ravenInstance.getContext()
    const reason = _.get(context, ['tags', ERROR_TYPE_TAG_KEY]) || DEFAULT_SUBMIT_ERROR

    let errorMessage = translations.t('submitFailed')

    let reasonBody

    if (typeof err === 'string') {
      reasonBody = err
    } else {
      reasonBody = err.name
    }

    if (reason === SUBMIT_ERROR_TYPES.REGISTRATION_FAILED) {
      errorMessage = translations.t('registrationForm.generalError')
    }

    biLogger.log({
      evid: EVENTS.SUBMISSION_FAILURE,
      ...getSubmitErrorParamsForBi({
        $w,
        fields,
        wixLocation,
        reason,
        reason_body: reasonBody,
      }),
    })

    console.error(`form submit failed with: ${err}`) //eslint-disable-line no-console

    showFormError($message, errorMessage)

    ravenInstance.captureException(err, {
      extra: {
        error: serializeError(err),
      },
    })

    ravenInstance.setExtraContext()
  }
}

const getUserInfo = fields => {
  const wantedFieldTypes = [
    FormsFieldPreset.FIRST_NAME,
    FormsFieldPreset.LAST_NAME,
    FormsFieldPreset.PHONE,
    FormsFieldPreset.EMAIL,
  ]
  const userInfo = {}

  fields.forEach(field => {
    const {
      connectionConfig: { fieldType },
    } = field
    if (!_.isEmpty(field.value) && wantedFieldTypes.includes(fieldType)) {
      userInfo[fieldType] = field.value
    }
  })

  return userInfo
}

const registerSubmitButtonIfExists = ($w, submitArgs, strategyImp) => {
  const $submitButton = submitUtils.getSubmitButton($w)
  // TODO: This is incorrect search for relevant strategy
  if (!$submitButton) {
    return
  }
  $submitButton.onClick(ravenInstance.wrap(() => onSubmit(submitArgs, strategyImp)))
  // FIXME - Check why Raven.wrap does not catch exception (replaced throw err with captureException to overcome this for now)
}

const registerCaptchaFieldIfExists = ($w, { biLogger, visitorId, formRefId }) => {
  const $captchaField = getCaptchaField($w)

  if (!$captchaField) {
    return
  }

  $captchaField.onVerified(() => onCaptchaVerify(biLogger, { visitorId, formRefId }))
  $captchaField.onTimeout(() => onCaptchaTimeout(biLogger, { visitorId, formRefId }))
}

const registerMultiStepForm = (
  $w,
  $multiStepForm,
  { wixLocation, wixWindow, strategy, biLogger }
) => {
  let onNavigationEnd = Promise.resolve()
  const previousButtons = submitUtils.getPreviousButtons($w)
  const nextButtons = submitUtils.getNextButtons($w)
  const fields = submitUtils.getFields({ $w, roles: FIELDS_ROLES })
  const {
    getCurrentFields,
    saveDatePickersInState,
    fillDatePickersInState,
    fillFieldsValues,
  } = submitUtils.registerFieldsToStates($multiStepForm, fields, onNavigationEnd)
  const { uploadFields, getUploadFieldsData } = submitUtils.registerFieldsToCache()

  nextButtons.forEach(button => {
    button.onClick(() => {
      onNavigationEnd = submitUtils.navigateToNextStep(
        { $w, $multiStepForm, $nextButton: button },
        {
          onNavigationEnd,
          getCurrentFields,
          uploadFields,
          saveDatePickersInState,
          fillDatePickersInState,
          strategy,
          wixLocation,
          wixWindow,
          biLogger,
          visitorId,
        }
      )
    })
  })
  previousButtons.forEach(button => {
    button.onClick(() => {
      onNavigationEnd = submitUtils.navigateToPreviousStep(
        { $w, $multiStepForm, $previousButton: button },
        { saveDatePickersInState, fillDatePickersInState, onNavigationEnd, biLogger, visitorId }
      )
    })
  })
  return { getUploadFieldsData, fillFieldsValues }
}

const pageReadyImpl = ($w, payload) => {
  if (!$w(`@${ROLE_FORM}`).length) {
    return
  }

  appLoadStarted()

  const {
    window: wixWindow,
    location: wixLocation,
    user: wixUsers,
    site: wixSite,
    pay: wixPay,
  } = payload

  try {
    ravenInstance.setUserContext({ id: wixLocation.url })
  } catch (err) {
    ravenInstance.captureException(err)
    ravenInstance.setExtraContext()
  }

  biLogger = initBiLogger({ defaults: { msid: metaSiteId } })
  const form = $w(`@${ROLE_FORM}`)
  const {
    collectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
  } = form.connectionConfig

  const preset = _.get(form.connectionConfig, 'preset')

  if (preset) {
    ravenInstance.setTagsContext({ preset })
  }

  const plugins = _.get(form.connectionConfig, 'plugins')
  const plugin = _.get(_.first(plugins), 'id')
  const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
  const isPaymentForm = !!paymentPlugin && !!paymentPlugin.payload
  const isMultiStepForm = !!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)

  if (plugin) {
    ravenInstance.setTagsContext({ plugin })
  }

  const locale = _.isEmpty(wixSite.language) ? 'en' : wixSite.language

  if (locale) {
    ravenInstance.setTagsContext({ locale })
    fetchTranslations.resolveData(translations.init(locale))
  }

  const formId = form.uniqueId
  const validCollectionId = getValidCollectionId(formId, collectionId)
  const $successMessage: any = $w(`@${ROLE_MESSAGE}`)
  const $downloadMessage: any = $w(`@${ROLE_DOWNLOAD_MESSAGE}`)
  let submitArgs: any = {
    $w,
    collectionId: validCollectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
    wixLocation,
    wixWindow,
    wixUsers,
    wixSite,
    wixPay,
    isPaymentForm,
    isMultiStepForm,
  }

  if (_.get($successMessage, 'hide')) {
    $successMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $successMessage,
    }
  }
  if (_.get($downloadMessage, 'hide')) {
    $downloadMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $downloadMessage,
    }
  }
  const strategy = _.find(strategies, s => s.isEnabled($w))
  if (!strategy) {
    return
  }
  const strategyImp = new strategy(submitArgs, linksUtil, ravenInstance)

  if (isMultiStepForm) {
    const { getUploadFieldsData, fillFieldsValues } = registerMultiStepForm($w, form, {
      wixLocation,
      wixWindow,
      strategy: strategyImp,
      biLogger,
    })
    submitArgs = {
      ...submitArgs,
      getUploadFieldsData,
      fillFieldsValues,
    }
  }

  registerSubmitButtonIfExists($w, submitArgs, strategyImp)
  registerCaptchaFieldIfExists($w, { biLogger, visitorId: visitorId, formRefId: formId })
  addContactsValidation($w, ravenInstance)

  appLoaded()
}

export const createControllers = controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: pageReadyImpl,
    })
  )
}
