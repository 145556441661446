import {
  ROLE_SUBMIT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  STEP_ROLE,
} from '../constants/roles'
import {
  isCheckbox,
  isUploadButton,
  isCaptchaField,
  isDatePicker,
  shouldSendData,
} from './viewer-utils'
import * as _ from 'lodash'
import { EVENTS } from '../constants/bi-viewer'
import { Attachment } from './field-dto/field-dto'
import { getExperiments } from './viewer-app-impl'

export const ERROR_TYPE_TAG_KEY = 'reason'

export const DEFAULT_SUBMIT_ERROR = 'unknown client error'

export const SUBMIT_ERROR_TYPES = {
  GET_ATTACHMENTS: 'get attachments',
  VALIDATE_FIELDS: 'validate fields',
  GET_FIELDS: 'get fields',
  GET_COLLECTION_FIELDS: 'get collection fields',
  SEND_TO_SERVER: 'send to server',
  SEND_TO_WIX_DATA: 'send to wix data',
  RESET_FIELDS: 'reset fields',
  SEND_LEAD: 'send lead',
  NAVIGATE_NEXT_STEP: 'navigate next step',
  NAVIGATE_PREVIOUS_STEP: 'navigate previous step',
  REGISTRATION_FAILED: 'registration failed',
}

export interface SubmitUtils {
  getSubmitButton
  getPreviousButtons
  getNextButtons
  getFields
  sendFieldsToServer
  sendWixAnalytics
  resetFields
  navigateToNextStep
  navigateToPreviousStep
  registerFieldsToStates
  navigateToStepByOffset
  registerFieldsToCache
  validateFields
  getAttachments
}

export const initSubmitUtils = (ravenInstance): SubmitUtils => {
  const withCatch = (func: Function, type: string) => {
    try {
      ravenInstance.captureBreadcrumb({ message: type, category: 'submit-flow' })
      return func()
    } catch (err) {
      const errorType = _.get(ravenInstance.getContext(), ['tags', ERROR_TYPE_TAG_KEY])

      if (!errorType) {
        ravenInstance.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
      }

      ravenInstance.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
      throw err
    }
  }

  const withCatchAsync = async (func: Function, type: string) => {
    try {
      ravenInstance.captureBreadcrumb({ message: type, category: 'submit-flow' })
      return await func()
    } catch (err) {
      const errorType = _.get(ravenInstance.getContext(), ['tags', ERROR_TYPE_TAG_KEY])

      if (!errorType) {
        ravenInstance.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
      }

      throw err
    }
  }

  const getSubmitButton = $w => $w(`@${ROLE_SUBMIT_BUTTON}`)[0]

  const getPreviousButtons = $w => $w(`@${ROLE_PREVIOUS_BUTTON}`)

  const getNextButtons = $w => $w(`@${ROLE_NEXT_BUTTON}`)

  const getFields = ({ $w, roles }) =>
    withCatch(() => {
      const fields = roles.reduce((res, roleField) => res.concat($w(`@${roleField}`)), [])
      return _.uniqBy(fields, (field: { uniqueId: string }) => field.uniqueId)
    }, SUBMIT_ERROR_TYPES.GET_FIELDS)

  const validateFields = ({ fields, strategy }): boolean =>
    withCatch(() => {
      fields.forEach(field => field.updateValidityIndication && field.updateValidityIndication())
      return strategy.validateFields(fields)
    }, SUBMIT_ERROR_TYPES.VALIDATE_FIELDS)

  const getAttachments = (fields): Promise<Attachment[]> =>
    withCatchAsync(async () => {
      const uploadButtons = fields.filter(field => isUploadButton(field) && field.value.length > 0)

      return Promise.all(
        uploadButtons.map(async uploadButtonField => {
          const { url } = await uploadButtonField.startUpload()

          return {
            url,
            name: uploadButtonField.value[0].name,
            uniqueId: uploadButtonField.uniqueId,
          }
        })
      )
    }, SUBMIT_ERROR_TYPES.GET_ATTACHMENTS)

  const sendFieldsToServer = async ({ strategy, attachments, fields, viewMode, experiments }) =>
    withCatchAsync(async () => {
      return await strategy.execute({ attachments, fields, viewMode, experiments })
    }, SUBMIT_ERROR_TYPES.SEND_TO_SERVER)

  const getCurrentPageName = ({ wixSite, wixLocation }) => {
    const siteStructure = wixSite.getSiteStructure()

    const currentPath = wixLocation.path

    let currentPageName

    const validPageUrl = _.findLast(currentPath, url => !_.isEmpty(_.trim(url)))

    if (validPageUrl) {
      const currentPageStructure = _.find(siteStructure.pages, ['url', `/${validPageUrl}`])
      currentPageName = _.get(currentPageStructure, 'name')
    } else {
      const homePageStructure = _.find(siteStructure.pages, ['isHomePage', true])
      currentPageName = _.get(homePageStructure, 'name')
    }

    return currentPageName
  }

  const sendWixAnalytics = ({ wixSite, wixLocation, wixWindow }) => {
    withCatch(() => {
      const currentPageName = getCurrentPageName({ wixSite, wixLocation })

      if (!currentPageName) return

      wixWindow.trackEvent('Lead', {
        label: `Page Name: ${currentPageName}`,
      })
    }, SUBMIT_ERROR_TYPES.SEND_LEAD)
  }

  const resetFields = fields =>
    withCatch(() => {
      fields.forEach(field => {
        if (isUploadButton(field) || isCaptchaField(field)) {
          if ('reset' in field) {
            field.reset()
          }
          return
        }

        if (isCheckbox(field)) {
          field.checked = false
        } else {
          field.value = null
        }

        if ('resetValidityIndication' in field) {
          field.resetValidityIndication()
        }
      })
    }, SUBMIT_ERROR_TYPES.RESET_FIELDS)

  const navigateToStepByOffset = ($multiStepForm, offestIndex: number) => {
    const stateIdx = getCurrentStateIdx($multiStepForm, $multiStepForm.states)

    const nextState = $multiStepForm.states[stateIdx + offestIndex]

    if (nextState) {
      return $multiStepForm.changeState(nextState.id)
    }
  }

  const navigateToNextStep = (
    { $w, $nextButton, $multiStepForm },
    {
      onNavigationEnd,
      getCurrentFields,
      uploadFields,
      saveDatePickersInState,
      fillDatePickersInState,
      strategy,
      wixLocation,
      wixWindow,
      biLogger,
      visitorId,
    }
  ) =>
    withCatchAsync(async () => {
      $nextButton.disable()
      biLogger.log(getStepParamsForBi($w, $multiStepForm, visitorId, 'next'))
      await onNavigationEnd
      const fields = getCurrentFields()
      const isStepValid = validateFields({
        fields,
        strategy,
      })

      const experiments = await getExperiments()

      if (isStepValid) {
        if (shouldSendData(wixLocation, wixWindow, experiments)) {
          await uploadFields(fields)
        }
        saveDatePickersInState()
        await navigateToStepByOffset($multiStepForm, 1)
        fillDatePickersInState()
      }

      $nextButton.enable()
    }, SUBMIT_ERROR_TYPES.NAVIGATE_NEXT_STEP)

  const navigateToPreviousStep = (
    { $w, $previousButton, $multiStepForm },
    { onNavigationEnd, saveDatePickersInState, fillDatePickersInState, visitorId, biLogger }
  ) =>
    withCatchAsync(async () => {
      $previousButton.disable()
      biLogger.log(getStepParamsForBi($w, $multiStepForm, visitorId, 'back'))
      await onNavigationEnd
      saveDatePickersInState()
      await navigateToStepByOffset($multiStepForm, -1)
      fillDatePickersInState()
      $previousButton.enable()
    }, SUBMIT_ERROR_TYPES.NAVIGATE_PREVIOUS_STEP)

  const registerFieldsToStates = (
    multiStepForm,
    fields: any[],
    onNavigationEnd: Promise<void>
  ): {
    getCurrentFields: () => any
    saveDatePickersInState: (fields: any[]) => void
    fillDatePickersInState: (fields: any[]) => void
    fillFieldsValues: (fields: any[]) => void
  } => {
    const statesFields: { [uniqueId: string]: any[] } = multiStepForm.states.reduce(
      (acc, { uniqueId }) => {
        acc[uniqueId] = []
        return acc
      },
      {}
    )

    fields.forEach(field => {
      field.onViewportEnter(async () => {
        await onNavigationEnd
        const currentStateId: string = multiStepForm.currentState.uniqueId
        const isFieldExists: boolean = !!_.find(statesFields[currentStateId], [
          'uniqueId',
          field.uniqueId,
        ])
        if (!isFieldExists) {
          statesFields[currentStateId] = statesFields[currentStateId].concat(field)
        }
      })
    })

    const datePickerValues: {
      [uniqueId: string]: string
    } = {}

    const saveDatePickersInState = () => {
      statesFields[multiStepForm.currentState.uniqueId]
        .filter(isDatePicker)
        .forEach(({ value, uniqueId }) => (datePickerValues[uniqueId] = value))
    }
    const fillDatePickersInState = () => {
      fillFieldsValues(statesFields[multiStepForm.currentState.uniqueId])
    }
    const fillFieldsValues = (fields: any[]) => {
      fields.filter(isDatePicker).forEach(field => {
        field.value = datePickerValues[field.uniqueId] || field.value
      })
    }

    return {
      getCurrentFields: () => statesFields[multiStepForm.currentState.uniqueId],
      saveDatePickersInState,
      fillDatePickersInState,
      fillFieldsValues,
    }
  }

  const registerFieldsToCache = (): {
    uploadFields: (fields: any[]) => Promise<void>
    getUploadFieldsData: () => Attachment[]
  } => {
    const fieldsUploadData: {
      [uniqueId: string]: Attachment
    } = {}
    const uploadFields = async fields => {
      const unsavedFields = fields.filter(({ uniqueId, value }) => {
        return (
          !fieldsUploadData[uniqueId] ||
          (value[0] && value[0].name !== fieldsUploadData[uniqueId].name)
        )
      })

      if (unsavedFields.length) {
        const attachments = await getAttachments(unsavedFields)

        attachments.forEach(attachment => {
          fieldsUploadData[attachment.uniqueId] = attachment
        })
      }
    }
    const getUploadFieldsData = () => _.values(fieldsUploadData)

    return {
      uploadFields,
      getUploadFieldsData,
    }
  }

  const getStepParamsForBi = ($w, $multiStepForm, visitorId, action) => {
    const steps = $w(`@${STEP_ROLE}`)
    const stateIdx = getCurrentStateIdx($multiStepForm, steps)
    return {
      evid: EVENTS.USER_CLICKS_NAVIGATION_BUTTONS,
      form_comp_id: $multiStepForm.uniqueId,
      visitor_id: visitorId,
      step_no: stateIdx + 1,
      step_name: steps[stateIdx].connectionConfig.title,
      action,
      total_number_of_steps: steps.length,
    }
  }

  const getCurrentStateIdx = ($multiStepForm, states) =>
    _.findIndex(states, {
      uniqueId: $multiStepForm.currentState.uniqueId,
    })

  return {
    getSubmitButton,
    getPreviousButtons,
    getNextButtons,
    getFields,
    sendFieldsToServer,
    sendWixAnalytics,
    resetFields,
    navigateToNextStep,
    navigateToPreviousStep,
    registerFieldsToStates,
    navigateToStepByOffset,
    registerFieldsToCache,
    validateFields,
    getAttachments,
  }
}
